<template>
  <div class="list">
    <div class="list__item" v-for="item of items" v-bind:key="item.id">
      <div class="list__item__header">
        <div class="list__item__title">
          <p class="list__item__name">{{item.name}}</p>
          <p class="list__item__address">{{ item.address }}</p>
        </div>
        <svg
            v-if="isAvailable"
            @click="$emit('create',item)"
            class="list__item__add list__item__button"
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="4" fill="#D6E5FF"/>
          <path d="M12 6C12.6627 6 13.2 6.53726 13.2 7.2V10.8H16.8C17.4627 10.8 18 11.3373 18 12C18 12.6627 17.4627 13.2 16.8 13.2H13.2V16.8C13.2 17.4627 12.6627 18 12 18C11.3373 18 10.8 17.4627 10.8 16.8V13.2H7.2C6.53726 13.2 6 12.6627 6 12C6 11.3373 6.53726 10.8 7.2 10.8H10.8V7.2C10.8 6.53726 11.3373 6 12 6Z" fill="#6099FC"/>
        </svg>

        <svg
            v-else
            @click="$emit('delete',item)"
            class="list__item__remove list__item__button"
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="24" height="24" rx="4" fill="#D6E5FF"/>
          <rect x="6" y="11" width="12" height="2.4" rx="1.2" fill="#6099FC"/>
        </svg>
      </div>
      <div class="list__item__body" v-if="!isAvailable">
        <p class="list__item__created">{{formatCreatedAt(item.createdAt.date)}}</p>
        <p
            v-if="item.secondsToExpiredAt > 0"
            class="list__item__expired"
            :class="{
              'list__item__expired_normal': item.secondsToExpiredAt > 26260003,
              'list__item__expired_warning': item.secondsToExpiredAt < 26260003
            }">Осталось {{formatExpiredAt(item.secondsToExpiredAt)}}</p>
        <p
            v-else
            class="list__item__expired list__item__expired_disabled">В архиве</p>

      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "ManagerContractList",
  props:{
    isAvailable: Boolean,
    items: Array
  },
  methods:{
    formatCreatedAt(createdAt){
      return moment(createdAt).format("DD.MM.YYYY")
    },
    formatExpiredAt(secondsToExpiredAt){
      //2628003 это примерно 10 месяцев
      let minutes = ~~(secondsToExpiredAt/60);
      let hours = ~~(minutes/60)
      if (hours === 0){
        return `${minutes} мин`
      }
      let days = ~~(hours/24)
      if (days === 0){
        return `${hours} ч`
      }
      let months = ~~(days/30)
      if (months === 0){
        return `${days} дн`
      }

      return `${months} мес`
    }
  }
}
</script>

<style scoped lang="scss">
  .list{
    width: 243px;
    height: 469px;
    overflow: scroll;
    background: #F8F8F8;
    border: 1px solid #EDEDED;
    border-radius: 4px;

    padding-left: 10px;
    padding-right: 10px;
    &::-webkit-scrollbar{
      display: none;
    }
    padding-bottom: 20px;

    &__item{
      width: 223px;
      height: 80px;

      background: #FFFFFF;
      border-radius: 4px;

      margin-top: 10px;
      padding: 12px;


      &__header{
        display: flex;
        justify-content: space-between;
      }

      &__name{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        text-transform: capitalize;
        color: #343A40;
        margin: 0;
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

      }

      &__address{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #9C9C9C;
        margin: 0;
        margin-top: 2px;
        width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__created{
        margin: 0;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #9C9C9C;
      }

      &__expired{
        margin: 0;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        &_normal{
          color: #3CE49D;

        }

        &_warning{
          color: #E4A13C;
        }

        &_disabled{
          color: #9A9A9A;
        }
      }

      &__button{
        cursor: pointer;
        &:hover{
          opacity: .75;
        }
      }


      &__body{
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
</style>