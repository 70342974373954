<template>
  <div class="form" :class="[`form__`+((isEdit) ? 'edit' : 'create')]">
    <svg
        @click="$router.push({name: 'ManagersList'})"
        class="form__exit"
        width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.29289 16.2929C5.90237 16.6834 5.90237 17.3166 6.29289 17.7071C6.68342 18.0976 7.31658 18.0976 7.70711 17.7071L12 13.4142L16.2929 17.7071C16.6834 18.0976 17.3166 18.0976 17.7071 17.7071C18.0976 17.3166 18.0976 16.6834 17.7071 16.2929L13.4142 12L17.7071 7.70711C18.0976 7.31658 18.0976 6.68342 17.7071 6.29289C17.3166 5.90237 16.6834 5.90237 16.2929 6.29289L12 10.5858L7.70711 6.29289C7.31658 5.90237 6.68342 5.90237 6.29289 6.29289C5.90237 6.68342 5.90237 7.31658 6.29289 7.70711L10.5858 12L6.29289 16.2929Z" fill="#282828"/>
    </svg>
    <div class="form__personal">
      <div class="form__input-container">
        <p class="form__input-title">Персональные данные</p>
        <ManagerInput
            class="form__input"
            placeholder="Имя"
            :value="firstName"
          v-model="firstName"/>
        <ManagerInput
            class="form__input"
            placeholder="Фамилия"
            :value="lastName"
            v-model="lastName"/>
      </div>
      <div class="form__input-container">
        <p class="form__input-title">Контактные данные</p>
        <ManagerInput
            type="tel"
            class="form__input"
            placeholder="Телефон"
            :value="phone"
            v-model="phone"/>
        <ManagerInput
            class="form__input"
            placeholder="е-mail"
            :value="email"
            v-model="email"/>
      </div>
      <div class="form__input-container">
        <p class="form__input-title">Логин <strong class="form__input-title_invalid">{{(isLoginUsed) ? 'ЗАНЯТ' : ''}}</strong></p>
        <ManagerInput
            class="form__input"
            placeholder="Логин"
            :isCopy="isEdit && !isChangedLogin"
            :value="login"
            :is-invalid="isLoginUsed"
            v-model="login"
            @copy="onCopyData"/>
      </div>
      <div class="form__input-container">
        <p class="form__input-title">Пароль <strong class="form__input-title_invalid">{{(isInvalidPassword) ? 'НЕЛЬЗЯ' : ''}}</strong></p>
        <ManagerInput
            class="form__input"
            placeholder="Пароль"
            :is-invalid="isInvalidPassword"
            :value="password"
            v-model="password"/>
      </div>
      <div class="form__switch" v-if="isEdit">
        <p class="form__switch__title">Показывать перс.инфо</p>
        <ManagerSwitch
            size="md"
            class="form__switch__switch"
            :checked="isShowInfo"
            @change="onShowInfoChanged"/>
      </div>
      <div class="form__buttons"
           :class="{
            'form__buttons_hidden' : (this.isEdit && !this.isChanged)
          }">
        <svg
            :class="{'form__button_disabled' :  !this.isChanged && !isEdit}"
            @click="clear"
            width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="40" height="40" rx="20" transform="matrix(-1 0 0 1 41 1)" fill="white" stroke="#E2E5E9"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2929 25.2929C14.9024 25.6834 14.9024 26.3166 15.2929 26.7071C15.6834 27.0976 16.3166 27.0976 16.7071 26.7071L21 22.4142L25.2929 26.7071C25.6834 27.0976 26.3166 27.0976 26.7071 26.7071C27.0976 26.3166 27.0976 25.6834 26.7071 25.2929L22.4142 21L26.7071 16.7071C27.0976 16.3166 27.0976 15.6834 26.7071 15.2929C26.3166 14.9024 25.6834 14.9024 25.2929 15.2929L21 19.5858L16.7071 15.2929C16.3166 14.9024 15.6834 14.9024 15.2929 15.2929C14.9024 15.6834 14.9024 16.3166 15.2929 16.7071L19.5858 21L15.2929 25.2929Z" fill="#A8A8A8"/>
        </svg>
        <button
            class="form__button form__button_create"
            :class="{'form__button_disabled' : !isValidLength || isInvalidPassword}"
          @click="update">Сохранить изменения</button>
      </div>
      <button
          v-if="isEdit"
          @click="remove"
          class="form__button form__button_delete">Удалить аккаунт</button>
    </div>
    <div class="form__selected" :class="{'form_disabled' : !isEdit}">
      <ManagerDropdown v-model="filterType"/>
      <ManagerContractList
          :items="computedMyContracts"
          class="form__list"
          :is-available="false"
          @delete="onDeleteContract"/>
    </div>
    <div class="form__available" :class="{'form_disabled' : !isEdit}">
      <div class="form__search">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.062 11.837C13.1609 10.6851 13.8356 9.12493 13.8356 7.40722C13.8356 3.86214 10.9617 0.988281 7.41665 0.988281C3.87157 0.988281 0.997711 3.86214 0.997711 7.40722C0.997711 10.9523 3.87157 13.8262 7.41665 13.8262C9.24401 13.8262 10.893 13.0626 12.062 11.837ZM12.062 11.837L16.7749 16.5499" stroke="#E0E0E0" stroke-width="1.36259" stroke-linecap="round"/>
        </svg>
        <input class="form__search__input" placeholder="Поиск по имени" v-model="searchInput">
      </div>
      <ManagerContractList
          :items="computedAvailableContracts"
          class="form__list"
          :is-available="true"
          @create="onCreateContract"/>
    </div>
  </div>
</template>

<script>
import ManagerContractList from "@/components/Manager/ManagerContractList";
import ManagerInput from "@/components/Manager/ManagerInput";
import ManagerDropdown from "@/components/Manager/ManagerDropdown";
import ManagerSwitch from "@/components/Manager/ManagerSwitch";
export default {
  name: "ManagerForm",
  components: {ManagerSwitch, ManagerDropdown, ManagerInput, ManagerContractList},
  props:{
    isEdit: Boolean,
    firstNameImm: String,
    lastNameImm: String,
    phoneImm: String,
    emailImm: String,
    loginImm: String,
    passwordImm: String,
    isLoginUsed: Boolean,
    isShowInfoImm: Boolean,
    availableContracts: Array,
    managerContacts: Array
  },
  data(){
    return {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      login: "",
      password: "",
      isShow: false,
      searchInput: '',
      filterType: -1,
      isInvalidPassword: null,
      isShowInfo: false

    }
  },
  watch:{
    firstNameImm(newval){
      this.firstName = newval
    },
    lastNameImm(newval){
      this.lastName = newval
    },
    phoneImm(newval){
      this.phone = newval
    },
    emailImm(newval){
      this.email = newval
    },
    loginImm(newval){
      this.login = newval
    },
    passwordImm(newval){
      this.password = newval
    },
    isShowInfoImm(newval){
      this.isShowInfo = newval
    },
    login(){
      this.$emit("clearValidate")
    },
    password(newval) {
      console.log("check")
      console.log(newval)
      if (newval.length === 0) {
        this.isInvalidPassword = null
      } else this.isInvalidPassword = newval.length < 6 || /[ `!@#$%^&№*()_+\-=[\]{};':"\\|,.<>/?~]/.test(newval);
    }
  },
  computed:{
    isChanged(){
      return this.login !== this.loginImm || this.password !== this.passwordImm || this.firstName !== this.firstNameImm ||
          this.lastName !== this.lastNameImm || this.phone !== this.phoneImm || this.email !== this.emailImm || this.isShowInfoImm !== this.isShowInfo
    },
    isValidLength(){
      return this.login.length > 0 && this.password.length > 0 && this.firstName.length > 0 && this.lastName.length > 0 &&
          this.phone.length > 0 && this.email.length > 0
    },
    isChangedLogin(){
      return this.login != this.loginImm
    },
    computedAvailableContracts(){
      if (this.searchInput.length === 0) return this.availableContracts

      return this.availableContracts.filter(item => {
        return item.name.toLowerCase().includes(this.searchInput.toLowerCase())
      })
    },
    computedMyContracts(){
      if (this.filterType === -1) return this.managerContacts

      return this.managerContacts.filter(item => {
        if (this.filterType === 0){
          return item.secondsToExpiredAt > 0
        } else {
          return item.secondsToExpiredAt < 0
        }
      })
    },
  },
  methods:{
    update(){
      if (!this.isEdit){
        this.$emit("create",{
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          email: this.email,
          login: this.login,
          password: this.password,
        })
      } else {
        this.$emit("update",{
          firstName: this.firstName,
          lastName: this.lastName,
          phone: this.phone,
          email: this.email,
          login: this.login,
          password: this.password,
          isShowInfo: this.isShowInfo
        })
      }
    },
    clear(){
      this.firstName = this.firstNameImm
      this.lastName = this.lastNameImm
      this.phone = this.phoneImm
      this.email = this.emailImm
      this.login = this.loginImm
      this.password = this.passwordImm
      this.isShowInfo = this.isShowInfoImm

    },
    onShowInfoChanged(value){
      this.isShowInfo = value
    },
    remove(){
      this.$emit("delete")
    },
    onCreateContract(contract){
      this.$emit("createContract",contract)
    },
    onDeleteContract(contract){
      this.$emit("deleteContract",contract)
    },
    onCopyData(){
      const el = document.createElement('textarea');
      el.value = `Логин : ${this.loginImm} \nПароль : ${this.passwordImm}\n`;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
  }
}
</script>

<style scoped lang="scss">
  .form{
    width: 960px;
    height: 586px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
    position: relative;
    display: flex;
    padding-left: 24px;
    padding-top: 24px;

    &__exit{
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 24px;
      &:focus{
        opacity: .75;
      }
    }


    &__personal{
      display: flex;
      flex-direction: column;
    }

    &__input-container{
      display: flex;
      flex-direction: column;
    }

    &__input-title{
      height: 11px;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 9px;
      line-height: 11px;
      letter-spacing: 1px;
      text-transform: uppercase;

      color: #6099FC;

      margin-top: 10px;
      margin-bottom: 0;


      &_invalid{
        color: red;
      }
    }

    &__input{
      margin-top: 8px;
      margin-bottom: 2px;
    }

    &__buttons{
      display: flex;
      margin-top: 24px;

      &_disabled{
        pointer-events: none;
        opacity: .5;
      }

      &_hidden{
        pointer-events: none;
        opacity: 0;
      }

      svg{
        cursor: pointer;

        &:hover{
          opacity: .75;
        }
      }
    }

    &__button{
      height: 40px;
      border-radius: 25.5px;
      border: 0;
      outline: 0;
      cursor: pointer;
      font-family: 'Roboto';
      font-style: normal;

      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      color: #FFFFFF;

      &:hover{
        opacity: .75;
      }

      &_create{
        width: 198px;
        background: #6099FC;
        margin-left: 10px;
      }

      &_disabled{
        opacity: .5;
        pointer-events: none;
      }

      &_delete{
        background: #FF0000;
        width: 161px;
        margin-top: 48px;
      }
    }

    &__available{
      margin-left: 26px;
      margin-bottom: 2px;
      margin-top: 21px;
    }

    &_disabled{
      pointer-events: none;
      opacity: .5;
    }

    &__list{
      margin-top: 10px;
    }

    &__search{
      background: #FAFAFA;
      border-radius: 4px;
      width: 243px;
      height: 36px;
      padding-left: 10px;
      display: flex;
      align-items: center;

      &__input{
        background: transparent;
        border: 0;
        outline: 0;
        margin-left: 10px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        width: 191px;
        height: 36px;

        &:focus{
          outline: 0;
        }

        &::placeholder{
          color: #E0E0E0;
        }
      }
    }

    &__selected{
      margin-top: 19px;
      margin-left: 78px;
    }

    &__switch{
      display: flex;
      align-items: center;

      &__title{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }

      &__switch{
        margin-left: 8px;
      }
    }
  }


  .form__edit {
    .form__personal {
      .form__switch {
        margin-bottom: -20px;
      }
      .form__button_delete {
        margin-top: 20px;
      }
    }
  }
</style>