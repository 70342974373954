<template>
  <input
      v-if='mutType === "tel"'
      :type="mutType"
      class="input"
      :class="{'input_invalid' : isInvalid}"
      :placeholder="placeholder"
      :value="value"
      v-mask="'+7(###)###-##-##'"
      @input="changeValue($event.target.value,$event)"/>
  <div style="position: relative" v-else>
    <input
        :type="mutType"
        class="input"
        :class="{'input_invalid' : isInvalid}"
        :placeholder="placeholder"
        :value="value"
        @input="changeValue($event.target.value,$event)"/>
    <svg
        v-if="isCopy"
        @click="$emit('copy')"
        width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 13H14.25H15ZM11 17V16.25V17ZM1 13H0.25H1ZM1 8.65685H1.75H1ZM8.65685 1V1.75V1ZM11 1V0.250001V1ZM2.17157 5.82843L2.7019 6.35876H2.7019L2.17157 5.82843ZM5.82843 2.17157L6.35876 2.7019V2.7019L5.82843 2.17157ZM19 9H18.25H19ZM19 17H19.75H19ZM15 21V20.25V21ZM9 21V21.75V21ZM7 3L6.25 3V3H7ZM3 7L3 7.75H3V7ZM14.25 5L14.25 13H15.75L15.75 5H14.25ZM11 16.25H5V17.75H11V16.25ZM1.75 13L1.75 8.65685H0.25L0.25 13H1.75ZM8.65685 1.75L11 1.75V0.250001L8.65685 0.250001V1.75ZM2.7019 6.35876L6.35876 2.7019L5.2981 1.64124L1.64124 5.2981L2.7019 6.35876ZM8.65685 0.250001C7.39708 0.25 6.18889 0.750446 5.2981 1.64124L6.35876 2.7019C6.96825 2.09241 7.7949 1.75 8.65685 1.75V0.250001ZM1.75 8.65685C1.75 7.7949 2.09241 6.96825 2.7019 6.35876L1.64124 5.2981C0.750445 6.18889 0.25 7.39708 0.25 8.65685H1.75ZM14.25 13C14.25 14.7949 12.7949 16.25 11 16.25V17.75C13.6234 17.75 15.75 15.6234 15.75 13H14.25ZM15.75 5C15.75 2.37665 13.6234 0.250001 11 0.250001V1.75C12.7949 1.75 14.25 3.20508 14.25 5H15.75ZM5 16.25C3.20507 16.25 1.75 14.7949 1.75 13H0.25C0.25 15.6234 2.37665 17.75 5 17.75V16.25ZM18.25 9V17H19.75V9H18.25ZM15 20.25H9V21.75H15V20.25ZM18.25 17C18.25 18.7949 16.7949 20.25 15 20.25V21.75C17.6234 21.75 19.75 19.6234 19.75 17H18.25ZM19.75 9C19.75 6.37665 17.6234 4.25 15 4.25V5.75C16.7949 5.75 18.25 7.20508 18.25 9H19.75ZM9 20.25C7.20507 20.25 5.75 18.7949 5.75 17H4.25C4.25 19.6234 6.37665 21.75 9 21.75V20.25ZM6.25 1L6.25 3L7.75 3L7.75 1L6.25 1ZM3 6.25L1 6.25L1 7.75L3 7.75L3 6.25ZM6.25 3C6.25 4.79493 4.79492 6.25 3 6.25V7.75C5.62335 7.75 7.75 5.62335 7.75 3H6.25Z" fill="#343A40"/>
    </svg>

  </div>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  name: "ManagerInput",
  props:{
    placeholder: String,
    value: String,
    type: String,
    isTrimEnable : Boolean,
    isRussianSymbolsDisabled: Boolean,
    isCopy: Boolean,
    isInvalid: Boolean
  },
  directives: {
    mask
  },
  watch:{
    type:{
      immediate:true,
      deep: true,
      handler(newVal){
        this.mutType = newVal
      }
    }
  },
  data(){
    return {
      isShowPassword: false,
      mutType : 'input'
    }
  },
  methods:{
    changeValue(value,ev) {
      if (this.isTrimEnable) {
        value = value.replace(/ /g,'')
      }
      if (this.isRussianSymbolsDisabled){
        value = value.replace(/[^a-zA-Z0-9]+/gm,'')
      }
      ev.target.value = value
      console.log(value)
      this.$emit('input', value)
    },
  }
}
</script>

<style scoped lang="scss">
.input{
  width: 250px;
  height: 40px;

  background: #FFFFFF;
  border: 1px solid #E2E5E9;
  border-radius: 8px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;

  color: #343A40;
  padding-left: 16px;

  &::placeholder{
    color: #A8A8A8;
  }

  &:focus{
    outline: 0;
  }

  &_invalid{
    border-color: red;
  }
}


svg{
  cursor: pointer;
  position: absolute;
  right: 11px;
  top: 9px;

  &:hover{
    opacity: .75;
  }
}
</style>