<template>
  <div class="form__dropdown dropdown" @click="isShow = !isShow">
    <div class="dropdown__selected">
      <p class="dropdown__selected__text">{{selected}}</p>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 7.5L10 12.5L15 7.5" stroke="#282828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

    </div>
    <div class="dropdown__list" :class="{'dropdown__list_expand' : isShow}">
      <p class="dropdown__list__item" @click="onSelect(-1,'Все')">Все</p>
      <p class="dropdown__list__item" @click="onSelect(0,'Активные')">Активные</p>
      <p class="dropdown__list__item" @click="onSelect(1,'Неактивные')">Неактивные</p>

    </div>
  </div>
</template>

<script>
export default {
  name: "ManagerDropdown",
  data(){
    return {
      isShow: false,
      selected: 'Все'
    }
  },
  methods:{
    onSelect(val,name){
      this.selected = name
      this.$emit("input",val)
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown{
  background: #FFFFFF;
  border: 1px solid #E2E5E9;
  border-radius: 8px;

  width: 243px;
  height: 40px;

  position: relative;
  cursor: pointer;

  &__selected{
    padding-left: 16px;
    padding-right: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    &__text{
      margin: 0;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1px;

      /* Текст */

      color: #343A40;
      width: 188px;
    }
  }

  &__list{
    position: absolute;
    top: 45px;
    left: 0;
    max-height: 0px;
    width: 243px;
    background: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border: 1px solid #f5f5f5;
    opacity: 0;

    transition: opacity .3s, max-height .3s;



    &_expand{
      max-height: 800px;
      opacity: 1;
    }

    &__item{
      /* Введите текст */
      margin: 0;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1px;
      color: #343A40;
      padding-left: 10px;
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #f5f5f5;
      background: white;


      &:hover{
        opacity: .5;
      }
    }


  }
}
</style>